import jQuery from 'jquery';

function onClick(this: HTMLElement): void {
    const checkedProp = jQuery('#burger-toggle').prop('checked');

    const isOpen = typeof checkedProp === 'boolean' ? !checkedProp : true;

    jQuery(this)
        .attr('aria-expanded', isOpen.toString())
        .toggleClass('is-active', isOpen);
}

/**
 * Attach the navburger events.
 */
export function attach(): void {
    jQuery('.navbar-burger').on('click', onClick);
}

import $ from 'jquery'; // tslint:disable-line match-default-export-name
import PhotoSwipe from 'photoswipe';

interface IControl {
    name: string;
    option: string;
    onInit?(el: HTMLElement): void;
    onTap?(): void;
}

interface IItem extends PhotoSwipe.Item {
    img: {
        naturalWidth: number;
    };
    loading: boolean;
    thumb: string;
    title: string;
}

interface IOptions extends PhotoSwipe.Options {
    arrowEl: boolean;
    barsSize: { top: number };
    captionEl: boolean;
    clickToCloseNonZoomable: boolean;
    closeEl: boolean;
    closeElClasses: string[];
    counterEl: boolean;
    fitControlsWidth: number;
    indexIndicatorSep: string;
    loadingIndicatorDelay: number;
    preloaderEl: boolean;
    tapToClose: boolean;
    tapToToggleControls: boolean;
    thumbnailsEl: boolean;
    timeToIdle: number;
    timeToIdleOutside: number;
    addCaptionHTMLFn(item: IItem, captionEl: JQuery, isFake?: boolean): boolean;
    getNumItemsFn(): number;
}

interface IPswp extends PhotoSwipe<IOptions> {
    currItem: IItem;
    items: IItem[];
    likelyTouchDevice: boolean;
    scrollWrap: HTMLElement;
    allowProgressiveImg(): boolean;
    toggleDesktopZoom(arg: any): void; // tslint:disable-line
}

/**
 * Looks like shit because it is based on https://github.com/dimsemenov/PhotoSwipe/blob/master/src/js/ui/photoswipe-ui-default.js
 */
export function photoSwipeUi(pswp: IPswp, framework: PhotoSwipe.UIFramework): void {
    // @ts-ignore
    const ui = this; // tslint:disable-line

    let overlayUIUpdated = false;
    let controlsVisible = true;
    let controls: JQuery;
    let captionContainer: JQuery;
    let indexIndicator: JQuery;
    let isIdle: boolean;
    let loadingIndicator: JQuery;
    let loadingIndicatorHidden: boolean;
    let loadingIndicatorTimeout: NodeJS.Timer;
    let galleryHasOneSlide: boolean;
    let options: IOptions;
    let blockControlsTap: boolean;
    let thumbnailsContainer: JQuery;

    const defaultUIOptions: Partial<IOptions> = {
        barsSize: { top: 44 },
        closeElClasses: ['item', 'caption', 'zoom-wrap', 'ui', 'top-bar'],
        loadingIndicatorDelay: 1000, // 2s
        timeToIdle: 4000,
        timeToIdleOutside: 1000,

        addCaptionHTMLFn(item: IItem, captionEl: JQuery): boolean {
            if (!item.title) {
                captionEl.empty();

                return false;
            }
            captionEl.html(item.title);

            return true;
        },

        arrowEl: true,
        captionEl: true,
        closeEl: true,
        counterEl: true,
        preloaderEl: true,
        thumbnailsEl: true,

        clickToCloseNonZoomable: true,
        fitControlsWidth: 1200,
        indexIndicatorSep: ' / ',
        tapToClose: false,
        tapToToggleControls: true,
    };

    const uiElements: IControl[] = [
        {
            name: 'caption',
            option: 'captionEl',
            onInit(el: HTMLElement): void {
                captionContainer = $(el);
            },
        },
        {
            name: 'thumbnails',
            option: 'thumbnailsEl',
            onInit(el: HTMLElement): void {
                thumbnailsContainer = $(el);

                (pswp.items || []).forEach((item, i) => {
                    thumbnailsContainer.append(
                        $(`<img src="${item.thumb}">`).on('click', () => {
                            pswp.goTo(i);
                        }),
                    );
                });
            },
        },
        {
            name: 'counter',
            option: 'counterEl',
            onInit(el: HTMLElement): void {
                indexIndicator = $(el);
            },
        },
        {
            name: 'button--close',
            onTap(): void {
                pswp.close();
            },
            option: 'closeEl',
        },
        {
            name: 'button--arrow--left',
            onTap(): void {
                pswp.prev();
            },
            option: 'arrowEl',
        },
        {
            name: 'button--arrow--right',
            onTap(): void {
                pswp.next();
            },
            option: 'arrowEl',
        },
        {
            name: 'preloader',
            option: 'preloaderEl',
            onInit(el: HTMLElement): void {
                loadingIndicator = $(el);
            },
        },
    ];

    let idleInterval: NodeJS.Timer;
    let idleTimer: NodeJS.Timer;
    let idleIncrement = 0;

    const onIdleMouseMove = () => {
        clearTimeout(idleTimer);
        idleIncrement = 0;
        if (isIdle) {
            ui.setIdle(false);
        }
    };

    const onControlsTap = function(e: MouseEvent): boolean | undefined {
        if (blockControlsTap) {
            return true;
        }

        if (options.timeToIdle && options.mouseUsed && !isIdle) {
            // Reset idle timer
            onIdleMouseMove();
        }

        const target = e.target as HTMLElement;
        const clickedClass = target ? target.getAttribute('class') || '' : '';
        let found;

        for (const uiElement of uiElements) {
            if (uiElement.onTap && clickedClass.indexOf(`pswp__${uiElement.name}`) > -1) {
                uiElement.onTap();
                found = true;
            }
        }

        if (!found) {
            return undefined;
        }

        if (e.stopPropagation) {
            e.stopPropagation();
        }
        blockControlsTap = true;

        /* Some versions of Android don't prevent ghost click event
         * when preventDefault() was called on touchstart and/or touchend.
         *
         * This happens on v4.3, 4.2, 4.1,
         * older versions strangely work correctly,
         * but just in case we add delay on all of them)
         */
        const tapDelay = framework.features.isOldAndroid ? 600 : 30;
        setTimeout(() => {
            blockControlsTap = false;
        }, tapDelay);
    };
    const fitControlsInViewport = function(): boolean {
        return !pswp.likelyTouchDevice || options.mouseUsed || screen.width > options.fitControlsWidth;
    };

    const togglePswpClass = function($el: JQuery, cName: string, add: boolean): void {
        $el.toggleClass(`pswp__${cName}`, add);
    };

    // Add class when there is just one item in the gallery
    // (by default it hides left/right arrows and 1ofX counter)
    const countNumItems = function(): void {
        const hasOneSlide = (options.getNumItemsFn ? options.getNumItemsFn() : 1) === 1;

        if (hasOneSlide !== galleryHasOneSlide) {
            togglePswpClass(controls, 'ui--one-slide', hasOneSlide);
            galleryHasOneSlide = hasOneSlide;
        }
    };

    const hasCloseClass = function(target: Element): boolean {
        for (const cls of options.closeElClasses) {
            if ($(target).hasClass(`pswp__${cls}`)) {
                return true;
            }
        }

        return false;
    };

    const onMouseLeaveWindow = (e: MouseEvent) => {
        const from = e.relatedTarget as HTMLElement;
        if (from && from.nodeName !== 'HTML') {
            return;
        }
        clearTimeout(idleTimer);
        idleTimer = setTimeout(() => {
            ui.setIdle(true);
        }, options.timeToIdleOutside);
    };

    const toggleLoadingIndicator = (hide: boolean) => {
        if (loadingIndicatorHidden !== hide) {
            togglePswpClass(loadingIndicator, 'preloader--active', !hide);
            loadingIndicatorHidden = hide;
        }
    };

    const setupLoadingIndicator = () => {
        if (!options.preloaderEl) {
            return;
        }
        // Setup loading indicator
        toggleLoadingIndicator(true);

        pswp.listen('beforeChange', () => {
            clearTimeout(loadingIndicatorTimeout);

            // Display loading indicator with delay
            loadingIndicatorTimeout = setTimeout(() => {
                if (!pswp.currItem || !pswp.currItem.loading) {
                    toggleLoadingIndicator(true); // Hide preloader

                    return;
                }
                if (
                    pswp.currItem &&
                    pswp.currItem.loading &&
                    (!pswp.allowProgressiveImg() || (pswp.currItem.img && !pswp.currItem.img.naturalWidth))
                ) {
                    /* Show preloader if progressive loading is not enabled,
                     * or image width is not defined yet (because of slow connection)
                     */
                    toggleLoadingIndicator(false);
                }
            }, options.loadingIndicatorDelay);
        });
        pswp.listen('imageLoadComplete', (_, item) => {
            if (pswp.currItem === item) {
                toggleLoadingIndicator(true);
            }
        });
    };

    const applyNavBarGaps = (item: IItem) => {
        const gap = item.vGap || { bottom: 0, top: 0 };

        if (!fitControlsInViewport()) {
            gap.top = gap.bottom = 0;

            return;
        }
        const bars = options.barsSize;
        gap.bottom = $('.pswp__bottom-bar').height() || 0;

        // Height of top bar is static, no need to calculate it
        gap.top = bars.top;
    };

    const setupIdle = () => {
        if (!options.timeToIdle) {
            return;
        }
        // Hide controls when mouse is used
        pswp.listen('mouseUsed', () => {
            framework.bind(document, 'mousemove', onIdleMouseMove);
            framework.bind(document, 'mouseout', onMouseLeaveWindow);

            idleInterval = setInterval(() => {
                idleIncrement += 1;
                if (idleIncrement === 2) {
                    ui.setIdle(true);
                }
            }, options.timeToIdle / 2);
        });
    };
    const setupHidingControlsDuringGestures = () => {
        // Hide controls on vertical drag
        pswp.listen('onVerticalDrag', (now) => {
            if (controlsVisible && now < 0.95) {
                ui.hideControls();
            } else if (!controlsVisible && now >= 0.95) {
                ui.showControls();
            }
        });

        // Hide controls when pinching to close
        let pinchControlsHidden: boolean;
        pswp.listen('onPinchClose', (now) => {
            if (controlsVisible && now < 0.9) {
                ui.hideControls();
                pinchControlsHidden = true;
            } else if (pinchControlsHidden && !controlsVisible && now > 0.9) {
                ui.showControls();
            }
        });

        pswp.listen('zoomGestureEnded', () => {
            pinchControlsHidden = false;
            if (pinchControlsHidden && !controlsVisible) {
                ui.showControls();
            }
        });
    };

    const setupUIElements = () => {
        const setupSingleElement = (item: HTMLElement) => {
            const classAttr = item.className;

            for (const uiElement of uiElements) {
                if (classAttr.indexOf(`pswp__${uiElement.name}`) === -1) {
                    continue;
                }
                // @ts-ignore
                if (!options[uiElement.option]) {
                    framework.addClass(item, 'pswp__element--disabled');
                    continue;
                }

                // If element is not disabled from options
                framework.removeClass(item, 'pswp__element--disabled');
                if (uiElement.onInit) {
                    uiElement.onInit(item);
                }
            }
        };

        controls.children().each((_, child) => {
            setupSingleElement(child);
        });

        const bars = controls.children('.pswp__top-bar, .pswp__bottom-bar');
        if (!bars) {
            return;
        }
        bars.children().each((_, child) => {
            setupSingleElement(child);
        });
    };

    ui.init = () => {
        // Extend options
        framework.extend(pswp.options, defaultUIOptions, true);

        // Create local link for fast access
        options = pswp.options;

        // Find pswp__ui element
        controls = $(pswp.scrollWrap).children('.pswp__ui');

        setupHidingControlsDuringGestures();

        // Update controls when slides change
        pswp.listen('beforeChange', ui.update);

        // Toggle zoom on double-tap
        pswp.listen('doubleTap', (point) => {
            const initialZoomLevel = pswp.currItem.initialZoomLevel || 1;
            if (pswp.getZoomLevel() !== initialZoomLevel) {
                pswp.zoomTo(initialZoomLevel, point, 333);
            } else if (options.getDoubleTapZoom) {
                pswp.zoomTo(options.getDoubleTapZoom(false, pswp.currItem), point, 333);
            }
        });

        // Allow text selection in caption
        pswp.listen('preventDragEvent', (e: MouseEvent, _: boolean, preventObj: { prevent: boolean }) => {
            const t = e.target as Element;
            if (
                t &&
                t.getAttribute('class') &&
                e.type.indexOf('mouse') > -1 &&
                ((t.getAttribute('class') || '').indexOf('__caption') > 0 || /(SMALL|STRONG|EM)/i.test(t.tagName))
            ) {
                preventObj.prevent = false;
            }
        });

        // Bind events for UI
        pswp.listen('bindEvents', () => {
            framework.bind(controls.get(0), 'pswpTap click', onControlsTap);
            framework.bind(pswp.scrollWrap, 'pswpTap', ui.onGlobalTap);

            if (!pswp.likelyTouchDevice) {
                framework.bind(pswp.scrollWrap, 'mouseover', ui.onMouseOver);
            }
        });

        // Unbind events for UI
        pswp.listen('unbindEvents', () => {
            if (idleInterval) {
                clearInterval(idleInterval);
            }
            framework.unbind(document, 'mouseout', onMouseLeaveWindow);
            framework.unbind(document, 'mousemove', onIdleMouseMove);
            framework.unbind(controls.get(0), 'pswpTap click', onControlsTap);
            framework.unbind(pswp.scrollWrap, 'pswpTap', ui.onGlobalTap);
            framework.unbind(pswp.scrollWrap, 'mouseover', ui.onMouseOver);
        });

        // Clean up things when gallery is destroyed
        pswp.listen('destroy', () => {
            if (options.captionEl) {
                captionContainer.removeClass('pswp__caption--empty');
            }

            thumbnailsContainer.empty();

            controls.removeClass('pswp__ui--over-close');
            controls.addClass('pswp__ui--hidden');
            ui.setIdle(false);
        });

        if (!options.showAnimationDuration) {
            controls.removeClass('pswp__ui--hidden');
        }

        pswp.listen('initialZoomIn', () => {
            if (options.showAnimationDuration) {
                controls.removeClass('pswp__ui--hidden');
            }
        });

        pswp.listen('initialZoomOut', () => {
            controls.addClass('pswp__ui--hidden');
        });

        pswp.listen('parseVerticalMargin', applyNavBarGaps);

        setupUIElements();

        countNumItems();

        setupIdle();

        setupLoadingIndicator();
    };

    ui.setIdle = (idle: boolean) => {
        isIdle = idle;
        togglePswpClass(controls, 'ui--idle', isIdle);
    };

    ui.update = () => {
        // Don't update UI if it's hidden
        if (controlsVisible && pswp.currItem) {
            ui.updateIndexIndicator();

            ui.updateThumbnails();

            if (options.captionEl) {
                options.addCaptionHTMLFn(pswp.currItem, captionContainer);

                togglePswpClass(captionContainer, 'caption--empty', !pswp.currItem.title);
            }

            overlayUIUpdated = true;
        } else {
            overlayUIUpdated = false;
        }

        countNumItems();
    };

    ui.updateIndexIndicator = () => {
        if (!options.counterEl) {
            return;
        }
        indexIndicator.html(`${pswp.getCurrentIndex() + 1}${options.indexIndicatorSep}${options.getNumItemsFn()}`);
    };

    // tslint:disable-next-line no-any
    ui.onGlobalTap = (e: any) => {
        const target = (e.target || e.srcElement) as Element;

        if (blockControlsTap) {
            return;
        }

        if (e.detail && e.detail.pointerType === 'mouse') {
            // Close gallery if clicked outside of the image
            if (hasCloseClass(target)) {
                pswp.close();

                return;
            }

            if (framework.hasClass(target, 'pswp__img')) {
                if (
                    pswp.getZoomLevel() === 1 &&
                    pswp.getZoomLevel() <= (pswp.currItem ? pswp.currItem.fitRatio || -1 : -1)
                ) {
                    if (options.clickToCloseNonZoomable) {
                        pswp.close();
                    }
                } else {
                    pswp.toggleDesktopZoom(e.detail.releasePoint);
                }
            }
        } else {
            // Tap anywhere (except buttons) to toggle visibility of controls
            if (options.tapToToggleControls) {
                if (controlsVisible) {
                    ui.hideControls();
                } else {
                    ui.showControls();
                }
            }

            // Tap to close gallery
            if (options.tapToClose && (framework.hasClass(target, 'pswp__img') || hasCloseClass(target))) {
                pswp.close();

                return;
            }
        }
    };

    ui.onMouseOver = (e: MouseEvent) => {
        const target = e.target as Element;

        // Add class when mouse is over an element that should close the gallery
        togglePswpClass(controls, 'ui--over-close', hasCloseClass(target));
    };

    ui.hideControls = () => {
        controls.addClass('pswp__ui--hidden');
        controlsVisible = false;
    };

    ui.showControls = () => {
        controlsVisible = true;
        if (!overlayUIUpdated) {
            ui.update();
        }
        controls.removeClass('pswp__ui--hidden');
    };

    ui.updateThumbnails = () => {
        thumbnailsContainer.children().each((_, item) => {
            const $item = $(item);
            const isActive = $(item).attr('src') === pswp.currItem.thumb;

            $item.toggleClass('active', isActive);
            if (!isActive) {
                return undefined;
            }

            requestAnimationFrame(() => {
                const itemOffset = $item.offset();
                const parentOffset = thumbnailsContainer.offset();
                if (itemOffset && parentOffset) {
                    thumbnailsContainer.scrollLeft((itemOffset.left - parentOffset.left) / 2);
                }
            });
        });
    };
}

export type Fn<A, B> = (a: A) => B;
export type Fn2<A, B, C> = (a: A, b: B) => C;
export type Fn3<A, B, C, D> = (a: A, b: B, c: C) => D;

/**
 * Misnomer: partial application function.
 *
 * @param fn The function to be applied.
 * @param a The first bound argument.
 */
export const curry = <A, B, C>(fn: Fn2<A, B, C>, a: A) => (b: B) => fn(a, b);

/**
 * Misnomer: partial application function.
 *
 * @param fn The function to be applied.
 * @param a The first bound argument.
 * @param a The second bound argument.
 */
export const curry2 = <A, B, C, D>(fn: Fn3<A, B, C, D>, a: A, b: B) => (c: C) => fn(a, b, c);

/**
 * Get the value of a string, or a default value if it is falsy.
 *
 * @param a The possibly-falsy parameter
 * @param fallback The value to use.
 */
export const defaulting = (a: string | null | undefined, fallback: string) => a || fallback; // tslint:disable-line strict-boolean-expressions no-null-undefined-union

import jQuery from 'jquery';

import { attach as attachBurger } from './burger';
import { attach as attachGallery } from './gallery';
import { attach as attachNavbar } from './navbar';
import { register as registerServiceWorker } from './service-worker';

jQuery('html').toggleClass('no-js js');

attachBurger();

attachGallery();

registerServiceWorker();

const interval = setInterval(() => {
    const html = jQuery('html');

    if (html.hasClass('wf-active') || html.hasClass('wf-inactive')) {
        attachNavbar();
        clearInterval(interval);
    }
}, 18);

/* tslint:disable no-console */
function onUpdateFound(reg: ServiceWorkerRegistration): void {
    // The updatefound event implies that reg.installing is set; see
    // Link: https://w3c.github.io/ServiceWorker/#service-worker-registration-updatefound-event
    if (reg.installing === null) {
        throw new Error('Service Worker registration is null');
    }

    const installingWorker = reg.installing;

    installingWorker.onstatechange = function(): void {
        switch (installingWorker.state) {
            case 'installed':
                if (navigator.serviceWorker.controller) {
                    /*
                     * At this point, the old content will have been purged and the fresh content will
                     * have been added to the cache.
                     * It's the perfect time to display a "New content is available; please refresh."
                     * message in the page's interface.
                     */
                    console.log('New or updated content is available.');
                } else {
                    /*
                     * At this point, everything has been precached.
                     * It's the perfect time to display a "Content is cached for offline use." message.
                     */
                    console.log('Content is now available offline!');
                }
                break;

            case 'redundant':
                console.error('The installing service worker became redundant.');
                break;
            default:
            // Do nothing.
        }
    };
}

/**
 * The register function.
 */
export function register(): void {
    if (!('serviceWorker' in navigator)) {
        return;
    }

    window.addEventListener('load', function(): void {
        // Your service-worker.js *must* be located at the top-level directory relative to your site.
        // It won't be able to control pages unless it's located at the same level or higher than them.
        // *Don't* register service worker file in, e.g., a scripts/ sub-directory!
        // See https://github.com/slightlyoff/ServiceWorker/issues/468
        navigator.serviceWorker
            .register('/service-worker.js')
            .then(function(reg: ServiceWorkerRegistration): void {
                // The 'updatefound' event is fired if service-worker.js changes.
                reg.onupdatefound = () => {
                    onUpdateFound(reg);
                };
            })
            .catch(function(e: Error): void {
                console.error('Error during service worker registration:', e);
            });
    });
}

import jQuery from 'jquery';
import PhotoSwipe from 'photoswipe';

import { photoSwipeUi } from './photoswipe-ui';
import { defaulting } from './util/fn';

function numPlaceholdersToShow(numElements: number): number {
    const gallery: JQuery = jQuery('.gallery');

    const galleryWidth = gallery.outerWidth();
    if (!galleryWidth) {
        return 0;
    }

    const numElementsPerRow = Math.floor(galleryWidth / 250);
    const extraElements = numElements % numElementsPerRow;

    if (extraElements === 0) {
        return 0;
    }

    return numElementsPerRow - extraElements;
}

function attachLightbox(this: HTMLElement, evt: JQuery.Event): void {
    const galleryStrUID = jQuery(this).attr('data-gallery');

    if (!galleryStrUID) {
        return;
    }

    let galleryUID = parseInt(galleryStrUID, 10);
    if (isNaN(galleryUID)) {
        galleryUID = 0;
    }

    const element = jQuery('.pswp');
    const slides = getSlides(galleryUID);
    // tslint:disable-next-line
    const gallery = new PhotoSwipe(element.get(0), photoSwipeUi as any, slides, {
        bgOpacity: 0.8,
        galleryUID,
        index: 0,
    });

    gallery.init();

    evt.preventDefault();
}

function createGallery(this: HTMLElement): void {
    jQuery('[data-gallery-filter]').on('click', function(this: HTMLElement): void {
        const filter = this.getAttribute('data-gallery-filter');
        if (!filter) {
            throw new Error('Filter has no class specified.');
        }

        filterGallery(filter);
        jQuery('[data-gallery-filter]')
            .removeClass('active')
            .filter(this)
            .addClass('active');
    });

    togglePlaceholders();
    jQuery(window).on('resize', togglePlaceholders);
}

function getSlides(galleryUID: number): PhotoSwipe.Item[] {
    return jQuery(`[data-gallery="${galleryUID}"]`)
        .find('[data-image]')
        .toArray()
        .map((el: HTMLElement) => {
            const h = parseInt(defaulting(el.getAttribute('data-h'), ''), 10);
            const w = parseInt(defaulting(el.getAttribute('data-w'), ''), 10);
            const copy = defaulting(el.getAttribute('data-copy'), '');
            const description = defaulting(el.getAttribute('data-description'), '');
            const src = defaulting(el.getAttribute('data-image'), 'error');
            const thumb = src.replace(/(\.\w+)$/, '@thumb$1');

            if (isNaN(h) || isNaN(w)) {
                throw new Error('Gallery item must specify height and width');
            }

            return {
                h,
                src,
                thumb,
                title: `${description}<br><small>${copy}</small>`,
                w,
            };
        });
}

function filterGallery(filter: string): void {
    // FLIP animation.
    const gallery = jQuery('.gallery');
    const toAnimate: JQuery[] = gallery
        .children()
        .finish()
        .toArray()
        .map(jQuery);

    const initialValue: [JQuery<{}>[], JQuery<{}>[]] = [[], []];

    const [toShow, toHide] = toAnimate.reduce(([show, hide], $next: JQuery) => {
        if ($next.is(filter) && !$next.is(':visible') && !$next.is('.placeholder')) {
            return [show.concat($next), hide];
        }
        if (!$next.is(filter) && $next.is(':visible') && !$next.is('.placeholder')) {
            return [show, hide.concat($next)];
        }

        return [show, hide];
    }, initialValue);

    const allShown = toAnimate.filter((el) => el.is(filter) && !el.is('.placeholder'));
    const missingPlaceholders = numPlaceholdersToShow(allShown.length);
    gallery.children('.placeholder').each((i: number, element: HTMLElement) => {
        if (i >= missingPlaceholders) {
            toHide.push(jQuery(element));
        } else {
            toShow.push(jQuery(element));
        }
    });

    // First
    const initialPositions = toAnimate.map(($el) => $el.position());

    // Last
    toHide.forEach(($el) => $el.hide());
    toShow.forEach(($el) => $el.show());

    const finalPositions = toAnimate.map(($el) => $el.position());

    // Invert
    toHide.forEach(($el) => $el.show());
    toShow.forEach(($el) => $el.hide());

    toAnimate.forEach(($el, i) => {
        $el.css({
            left: initialPositions[i].left,
            position: 'absolute',
            top: initialPositions[i].top,
        });
    });

    // Play
    toHide.map(($el) =>
        $el.fadeOut({
            complete(): void {
                $el.css('position', 'static');
            },
            duration: 250,
            queue: false,
        }),
    );
    toShow.map(($el) =>
        $el.fadeIn({
            complete(): void {
                $el.css('position', 'static');
            },
            duration: 250,
            queue: false,
        }),
    );
    toAnimate.map(($el, i) =>
        $el.animate(
            {
                left: finalPositions[i].left,
                top: finalPositions[i].top,
            },
            {
                complete(): void {
                    $el.css('position', 'static');
                },
                duration: 250,
                queue: false,
            },
        ),
    );
}

function togglePlaceholders(): void {
    const shownImages: JQuery[] = jQuery('.gallery')
        .children()
        .filter((_, el) => {
            const $el = jQuery(el);

            return $el.is(':visible') && !$el.is('.placeholder');
        });

    const numMissingPlaceholders = numPlaceholdersToShow(shownImages.length);

    jQuery('.gallery .placeholder').each((i, element) => {
        jQuery(element).toggle(i < numMissingPlaceholders);
    });
}

/**
 * Attach the gallery events.
 */
export function attach(): void {
    jQuery(() => {
        jQuery('[data-gallery-container]').each(createGallery);

        jQuery('[data-gallery]').on('click', attachLightbox);
    });
}
